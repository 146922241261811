@import url('https://fonts.googleapis.com/css2?family=Flamenco:wght@300;400&family=Orbitron&family=Protest+Guerrilla&family=Roboto:wght@300;400;500;700&display=swap');

.protest-guerrilla-regular {
    font-family: "Protest Guerrilla", sans-serif;
    font-weight: 400;
    font-style: normal;
}

h3{
    color: whitesmoke;
    font-size: 2em;
}

@media screen and (max-width: 767px) {

    .logo {
        width: 200%;
        text-align: center;
        position: absolute;
        z-index: -10;
        top: 100px;
        left: -30px;
        opacity: 50%;
    }

    h1 {
        width: 100%;
        font-family: "Protest Guerrilla", sans-serif;
        font-weight: 400;
        font-size: 20vw;
        text-shadow: 2px 2px 7px whitesmoke;
        animation: 3s floating;
        animation-iteration-count: infinite;
        position: absolute;
        /*left: 27%;*/
        text-align: center;
        top: 1px;
        color: #f7570b;
    }

    .first {
        font-size: 4vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        /*rotate: 12deg;*/
        position: absolute;
        z-index: 10;
        top: 12%;
        left: 3%;
    }

    .second {
        font-size: 4vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        /*rotate: 12deg;*/
        position: absolute;
        z-index: 10;
        top: 17%;
        left: 5%;
    }

    .third {
        font-size: 5vw;
        line-height: 1.9em;
        color: white;
        text-shadow: 3px 3px 9px black;
        /*rotate: 12deg;*/
        position: absolute;
        z-index: 10;
        top: 3%;
        left: 2%;
    }

    .four {
        font-size: 4vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        /*rotate: 12deg;*/
        position: absolute;
        z-index: 10;
        top: 21%;
        left: 10%;
    }

    .five {
        font-size: 8vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        rotate: 12deg;
        position: absolute;
        z-index: 10;
        top: 32%;
        left: 30%;
    }
    .tel{
        color: greenyellow;
        text-underline: none;
        font-size: 2em;
    }

    .denso {
        width: 100px;
        height: auto;
        position: absolute;
        z-index: 3;
        top: 57%;
        left: 7%;
    }

    .ngk {
        width: 130px;
        height: auto;
        position: absolute;
        z-index: 3;
        top: 75%;
        left: 2%;
    }

    .trw {
        width: 100px;
        height: 100px;
        position: absolute;
        z-index: 4;
        top: 33%;
        left: 35px;
    }

    .honda {
        width: 200px;
        height: 200px;
        position: absolute;
        z-index: 5;
        top: 220px;
        left: 160px;
    }

    .kawasaki {
        width: 200px;
        height: 120px;
        position: absolute;
        z-index: 5;
        top: 480px;
        left: 120px;
    }

    .harley{
        width: 170px;
        height: 120px;
        position: absolute;
        z-index: 4;
        top: 150px;
        left: 180px;
    }

    .hiflo {
        width: 180px;
        height: 20px;
        position: absolute;
        z-index: 3;
        top: 260px;
        left: 10px;
    }

    .kews {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 0;
        top: 400px;
        left: 150px;
    }

}

@media screen and (min-width: 768px) {

    .logo {
        width: 850px;
        height: auto;
        position: absolute;
        top: -270px;
        opacity: 50%;
        z-index: 1;
    }

    h1 {
        width: 100%;
        font-family: "Protest Guerrilla", sans-serif;
        font-weight: 400;
        font-size: 12em;
        text-shadow: 2px 2px 7px whitesmoke;
        animation: 3s floating;
        animation-iteration-count: infinite;
        position: absolute;
        /*left: 27%;*/
        text-align: center;
        top: 10%;
        color: black;
    }

    .first {
        font-size: 3vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        rotate: 12deg;
        position: absolute;
        z-index: 10;
        top: 20%;
        left: 4%;
    }

    .second {
        font-size: 2vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        rotate: 12deg;
        position: absolute;
        z-index: 10;
        top: 43%;
        left: 10%;
    }

    .third {
        font-size: 2vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        rotate: 12deg;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 40%;
    }

   .four {
        font-size: 3vw;
        color: whitesmoke;
        text-shadow: 3px 3px 9px black;
        rotate: 3deg;
        position: absolute;
        z-index: 10;
        top: 1%;
        left: 70%;
    }

    .five {
        font-size: 3vw;
        color: white;
        text-shadow: 3px 3px 9px black;
        rotate: 12deg;
        position: absolute;
        z-index: 10;
        top: 75%;
        left: 10%;
    }

    .denso {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 0;
        top: 280px;
        left: 350px;
    }

    .ngk {
        width: 200px;
        height: auto;
        rotate: -20deg;
        position: absolute;
        z-index: 0;
        left: 190px;
        top: 450px;
    }

    .trw {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 0;
        top: 50px;
        left: 350px;
    }

    .honda {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 0;
        top: 320px;
        left: 50px;
    }

    .kawasaki {
        width: 600px;
        height: 120px;
        position: absolute;
        z-index: 0;
        top: 30px;
        left: 850px;
    }

    .harley {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 1;
        top: 190px;
        left: 50px;
    }

    .hiflo {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 0;
        top: 200px;
        left: 350px;
    }

    .kews {
        width: 200px;
        height: auto;
        position: absolute;
        z-index: 0;
        top: 100px;
        left: 50px;
    }

    .tel{
        color: greenyellow;
        text-underline: none;
    }
}
@keyframes floating {
    0%{
        text-shadow: 2px 2px 7px darkgrey;
    }
    25%{
        text-shadow: 8px 8px 12px darkgrey;
    }
    50%{
        text-shadow: 12px 12px 25px darkgrey;
    }
    75%{
        text-shadow: 8px 8px 12px darkgrey;
    }
    100%{
        text-shadow: 2px 2px 7px darkgrey;
    }
}
