@font-face {
    font-family: 'sunday';
    src: url("./fonts/sunday.ttf");
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: sunday;
}

body {
    width: 100vw;
    height: 100vh;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
