
.frame {
    margin: 0;
    animation: rize 1s;
    width: 90vw;
    height: 100%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.0);
    border-radius: 8px;
    position: absolute;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.cont {
    width: 80%;
    border: 1px solid white;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: 40px;
    gap: 40px;
    overflow-y: scroll;
}

.catSelect{
    width: 200px;
    height: 100%;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-flow: column;
    gap: 30px;
    padding-top: 30px;
    box-sizing: border-box;

}


@media screen and (max-width: 767px) {

    .item {
        height: 5%;
        font-size: 0.9em;
        cursor: pointer;
    }

    .catSelect {
        width: 200px;
        height: 100%;
        margin-right: 10px;
        border: 1px solid white;
        border-radius: 8px;
        color: white;
        display: flex;
        flex-flow: column;
        gap: 30px;
        padding-top: 30px;
        box-sizing: border-box;
    }

}

@media screen and (min-width: 768px) {

    .item {
        height: 5%;
        font-size: 1.5em;
        cursor: pointer;
    }

    .catSelect {
        width: 200px;
        height: 100%;
        border: 1px solid white;
        border-radius: 8px;
        color: white;
        display: flex;
        flex-flow: column;
        gap: 30px;
        padding-top: 30px;
        box-sizing: border-box;
    }

}




