.button{
    animation: rise 2s;
    width: fit-content;
    height: 100%;
    color: whitesmoke;
    font-size: 1.5em;
    line-height: 0.9em;
}

.button:hover{
    cursor: pointer;
}

@keyframes rise {
    0%{
        opacity: 0;
    }
    70%{
        opacity: 0;
    }
    100%{
        opacity: 100%;
    }

}