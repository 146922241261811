@media screen and (max-width: 767px) {

    .card {
        width: 90%;
        height: 440px;
        margin: 5%;
        border: 1px solid white;
        border-radius: 8px;
    }

    .moto {
        width: 50%;
        position: relative;
    }

    .desc {
        width: 80%;
        height: 250px;
        border-radius: 8px;
        position: absolute;
        left: 10%;
        color: whitesmoke;
        overflow-y: scroll;
        border: 1px solid white;
        padding-top: 10px;
        padding-bottom: 10px;
        box-sizing: border-box;
        line-height: 1.5em;
    }
}
@media screen and (min-width: 768px) {
    .card {
        width: 90%;
        height: fit-content;
        margin: 5%;
        border: 1px solid white;
        border-radius: 8px;
    }

    .moto {
        width: 30%;
        position: absolute;
        left: 10%;
    }

    .desc {
        width: 600px;
        position: absolute;
        left: 50%;
        color: whitesmoke;
    }
}


