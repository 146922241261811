@media screen and (max-width: 767px){
    .cntMdl {
        animation: rize 1s;
        width: 90vw;
        height: 65vh;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid white;
        border-radius: 8px;
        position: absolute;
        left: 5%;
        top: 30%;
    }
}
@media screen and (min-width: 768px){
    .cntMdl {
        animation: rize 1s;
        width: 90vw;
        height: 72vh;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid white;
        border-radius: 8px;
        position: absolute;
        left: 5%;
        top: 20%;
        overflow-y: scroll;
    }
}

@keyframes rize {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    50% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 90%;
        height: 75%;
        opacity: 100%;
    }
}