@media screen and (max-width: 767px){
    .logo{
        width: 90px;
        position: absolute;
        z-index: 3;
        top: 30px;
        right: 20px;
    }
}

@media screen and (min-width: 768px){
    .logo{
        width: 8%;
        position: absolute;
        z-index: 3;
        top: 0;
        right: 50px;
    }
}

.back {
    height: 100vh;
    background-image: url("/src/background/background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

