.mobileMenu{
    animation: rize 1.2s;
    width: 50%;
    height: 5%;
    padding-left: 30px;
    box-sizing: border-box;
    /* border: 1px solid white;*/
    border-radius: 7px;
    position: relative;
    top: 5%;
    left: 22%;
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
    font-size: 10px;
}

.bar{
    animation: rize 1.2s;
    width: 50%;
    height: 5%;
    padding-left: 30px;
    box-sizing: border-box;
   /* border: 1px solid white;*/
    border-radius: 7px;
    position: absolute;
    top: 9%;
    left: 20%;
    display: flex;
    flex-flow: row;
    justify-content: start;
    align-items: center;
    gap: 40px;
}



@keyframes rize {
    0%{
        width: 0;
        height: 0;
        opacity: 0;
    }
    50%{
        width: 0;
        height: 0;
        opacity: 0;
    }
    100%{
        width: 50%;
        height: 5%;
        opacity: 100%;
    }

}