@media screen and (max-width: 767px){
    .frame {
        animation: 1.9s rize;
        padding: 30px 6px 0 5px;
        width: 131px;
        height: 200px;
        border: 1px solid white;
        border-radius: 10px;
        color: whitesmoke;
        box-sizing: border-box;
        font-size: 0.58em;
    }
}

@media screen and (min-width: 768px){
    .frame {
        animation: 1s rize;
        padding: 30px 6px 0 5px;
        width: 300px;
        height: 400px;
        border: 2px solid white;
        border-radius: 10px;
        color: whitesmoke;
        box-sizing: border-box;
    }
}

img {
    width: 90%;
    border-radius: 10px;
}
@keyframes rize {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}
